import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CLHbid.com: Providing a leading edge online tender platform to market and sell farm and ranch land in Western Canada.`}</h1>
    <p>{`The team at `}<CLHbid mdxType="CLHbid" />{` has legal, agricultural and business backgrounds, and are well experienced in the sale of farms and ranches.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      